import axios from "axios";
import { notification } from "antd";

export const BASE_URL = "https://app.drinkup.com";
// export const BASE_URL = "http://localhost:1234";
export const API_URL = `${BASE_URL}/api`;

//Media location
export const MEDIA_LOCATION = `https://app.drinkup.com/static/media`;

// Boosts
export const GET_BOOSTS = `${API_URL}/boosts/GetBoostsAdmin`;
export const SELECT_BOOST = `${API_URL}/boosts/GetBoostById`;
export const ADD_BOOSTS = `${API_URL}/boosts/AddBoosts`;
export const UPDATE_BOOSTS = `${API_URL}/boosts/UpdateBoost`;
export const DELETE_BOOSTS = `${API_URL}/boosts/DeleteBoost`;
export const UPLOAD_BOOSTS = `${API_URL}/Boosts/Uploadfile`;

//Sizes
export const GET_SIZES = `${API_URL}/Sizes/GetSizesAdmin`;
export const SELECT_SIZE = `${API_URL}/Sizes/GetById`;
export const ADD_SIZE = `${API_URL}/Sizes/Add`;
export const UPDATE_SIZE = `${API_URL}/Sizes/Update`;
export const DELETE_SIZE = `${API_URL}/Sizes/Delete`;
export const UPLOAD_SIZES = `${API_URL}/Boosts/Uploadfile`;

//Machines
export const GET_MACHINES = `${API_URL}/Machines/Machines`
export const ADD_MACHINES = `${API_URL}/Machines/AddMachine`
export const SELECT_MACHINE = `${API_URL}/Machines/GetMachineDetail`
export const EDIT_MACHINE = `${API_URL}/Machines/UpdateMachine`
export const GET_BOOSTSIZES_BY_MACHINE = `${API_URL}/Machines/GetBoostsSizesByMachineById`

//Users
export const GET_USERS = `${API_URL}/User/GetUsers`
export const GET_USER = `${API_URL}/User/GetUser`
export const GET_USER_BY_ID = `${API_URL}/User/GetUserByID`
export const GET_ROLES = `${API_URL}/Role/ActiveRoles`
export const GET_ROLE = `${API_URL}/Role/ActiveRolesByUserId`
export const LOGIN_URI = `${API_URL}/User/Login` 
export const USER_ADD = `${API_URL}/User/Register` 
export const USER_UPDATE = `${API_URL}/User/Update` 
export const GET_USER_ROLES = `${API_URL}/Role/ActiveRoles` 
export const DELETE_USER = `${API_URL}/User/Delete` 


//Sales
export const GET_TRANSACTIONS = `${API_URL}/Transaction/sales`

//Offline Machines
export const GET_OFFLINE_MACHINES=`${API_URL}/Machines/GetOfflineMachines`
export const REMOVE_OFFLINE_MACHINE=`${API_URL}/Machines/RemoveOfflineMachine`

//Recipes
export const GET_RECIPE = `${API_URL}/Recipe/GetRecipe`
export const UPDATE_RECIPE = `${API_URL}/Recipe/UpdateRecipe` 

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally
    openNotification(
      "error",
      "Server error",
      "Something went wrong, please try again later"
    );
    return Promise.reject(error);
  }
);

export const GET_Req = async (endpoint, params) => {
  try {
    const response = await axios.get(`${endpoint}`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// export const POST_Req = async (endpoint, params) => {
//   try {
//     const response = await axios.post(`${endpoint}`, { params });
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
export const POST_Req = async (endpoint, params, headers) => {
    try {
      const response = await axios.post(
        endpoint,
        params, // Directly pass params as data
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...headers, // Include additional headers if provided
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const DELETE_Req = async (endpoint, params) => {
  try {
    const response = await axios.delete(`${endpoint}`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const PUT_Req = async (endpoint, params, headers) => {
    try {
      const response = await axios.put(
        endpoint,
        params, // Directly pass params as data
        {
          headers: {
            "Content-Type": "application/json",
            ...headers, // Include additional headers if provided
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const openNotification = (type, title, description) => {
  notification.open({
    type: type,
    message: title,
    description: description,
  });
};
